@import url('./variables.css');

.App {
  text-align: center;
  background-color: var(--background-color);
}

.Nav {
  position: sticky;
  top: 0;
  width: 100%;
  z-index: 1;
}

.line-divider {
  border-top: 1px solid #464646;
  margin: 20px 0;
  box-shadow: 0 2px 3px rgba(0, 0, 0, 0.2);
}